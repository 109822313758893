import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import JobPageMeta from '../JobPageMeta/JobPageMeta';
import SectionWrapper from '../SectionWrapper';
import SocialMediaShare from '../SocialMediaShare/SocialMediaShare';

import './JobPageDetails.scss';
import ButtonCTA from '../ButtonCTA';

/**
 * @return {html}
 */
const JobPageDetails = ({ jobAttributes, from = null }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <SectionWrapper>
      <div className="sw-section-job-details">
        <div className="grid-1fr-1fr2fr grid-gap">
          <div></div>
          <JobPageMeta contractTypes={jobAttributes.contractTypes.data} locations={jobAttributes.locations.data} />
        </div>

        <div className="grid-1fr-1fr2fr grid-gap">
          <div></div>
          <div className="font-weight-700">
            {jobAttributes.teaser}
          </div>
        </div>

        <div className="grid-1fr-1fr2fr grid-gap">
          <div>
            <h2 className="h5">{t('job-details.about-syncwork.label')}</h2>
          </div>
          <div>
            {t('job-details.about-syncwork.text')}
          </div>
        </div>

        <div className="grid-1fr-1fr2fr grid-gap">
          <div>
            <h2 className="h5">{t('job-details.your-duties')}</h2>
          </div>
          <div>
            {
              jobAttributes.tasks && (
                <div className="strapi-markdown">
                  <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
                    {jobAttributes.tasks}
                  </ReactMarkdown>
                </div>
              )
            }
          </div>
        </div>

        <div className="grid-1fr-1fr2fr grid-gap">
          <div>
            <h2 className="h5">{t('job-details.your-profile')}</h2>
          </div>
          <div>
            {
              jobAttributes.profile && (
                <div className="strapi-markdown">
                  <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
                    {jobAttributes.profile}
                  </ReactMarkdown>
                </div>
              )
            }
          </div>
        </div>

        <div className="grid-1fr-1fr2fr grid-gap">
          <div>
            <h2 className="h5">{t('job-details.our-offer')}</h2>
          </div>
          <div>
            {
              jobAttributes.offer && (
                <div className="strapi-markdown">
                  <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
                    {jobAttributes.offer}
                  </ReactMarkdown>
                </div>
              )
            }
          </div>
        </div>

        <div className="grid-1fr-1fr2fr grid-gap">
          <div>
            <h2 className="h5">{t('job-details.interested.label')}</h2>
          </div>
          <div>
            {jobAttributes.linkToJobApplication && (
              <div className="sw-button-group">
                <ButtonCTA
                  text="Bewerben"
                  theme="pacificBlue"
                  to={jobAttributes.linkToJobApplication}
                  icon="ExternalLink"
                  iconSize="24"
                />
              </div>
            )}
            <p>
              {t('job-details.interested.text')}
              {/*
              <a className="sw-link" href={`mailto:${jobAttributes.contactPerson.email}`}>{jobAttributes.contactPerson.email}</a>
            */}
            </p>
          </div>
        </div>

        <div className="grid-1fr-1fr2fr grid-gap">
          <div>
            <h2 className="h5">{t('job-details.share')}</h2>
          </div>
          <div>
            <SocialMediaShare />
          </div>
        </div>

        {
          from && (
            <div className="grid-1fr-1fr2fr grid-gap">
              <div />
              <div>
                <i className="icon icon-x1 icon-arrow-left" />
                <button type="button" className="sw-link" onClick={() => history.goBack()}>
                  {t('common.back-to-all-jobs')}
                </button>
              </div>
            </div>
          )
        }
      </div>
    </SectionWrapper>
  );
};

export default JobPageDetails;
