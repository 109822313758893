import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { StrapiBackEndUrlKind, getStrapiBackEndUrl } from '../../utils';

import './SectionImageBanner.scss';

const placeholder = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAALCAYAAAAeEY8BAAAACXBIWXMAAAsTAAALEwEAmpwYAAACy0lEQVQ4jZ2T6VNSYRjFQwVkEyQEDDU0UlMrsyytccqZypmm6e9paswxI/fEFWSRTRCRRQS0XDOX+q' +
  '9O571+qG8uH565986993l+5zznvXZt8w/Oq5LCKUrzxyjL/YIic4Dy1C5Uqz+gWdmCNl6EbjmPiugGKwcdr+JZGytCndiCKrkNZWoP8uwBSnNHKMmfQFY8/df/vOGy4u8zgI0jNvkpNVNzuBhcwUGGyDoqQxkYgykYl1jBNAx81kcIE8tLkAJCkd6XBJRQiKxwW' +
  'QBSi5+VmX2o2UwX34SBao0cVBVYg9mXgMW7AitLXM3+VZgIUxleh164kfguuaZYP6SQY0nQpQCE/XL+LJqIZgbabKJSiz+JG4tx1CxEUTcflqp2IQKbJ4ZqbwJmQlwXEHRLI1ZBB8vo5OUBSK3gDtXJHamZKZyFlcNrOMjOoQ0zS3C4/Gc1HUD9XAg33cuwCWcI' +
  'aqQLOq5NxfzIc1cAENRK7lDLJkaqt1JZrSfOQWE0cuidyUW0Trilapn0oNnlg2M2BDsBbQQ1Mw96OqdhD4XIAQFkFwbgx2L/5QxfBQNVRUtrfKtomI+gyRVA24QH7aNz6BiewUPWA97fEyAEu81v7FxFdSgL48omtOk9KNmr9LIAcv6kWtuFgeGzBjNUFkfTTBB' +
  '3qbxjZA5PnFPoHvqGp6wu3neOzKKd71r4jcMdQ+1SGiZxaiiiXASxcHLxFQi7RHo1azvcZQG2QAoOhq51yo+OsQV0f3GhZ3AcLwZG0TswhueDE3jmdOEx392nC83zUdh5UiyxAvQUcWUALVNcFc2jzpdE82xYUthFy8XAl/3D6PvoZH3F6/4R9AoIvns06UUbs3' +
  'CLK6tmECspQnUVAGX2kCnehiWygXrvKtpobee4Gz1U/+rTGN58cOLd+8+sIbzlfZ9wgy50jzMf00E0Mgc2htfIU6QWR/E/gL84FlRj5pA0LAAAAABJRU5ErkJggg==';

const overlayOpacity = (overlayLevel) => {
  const overlayLevels = {
    None: 0,
    Level1: 0.1,
    Level2: 0.2,
    Level3: 0.3,
    Level4: 0.4,
    Level5: 0.5,
    Level6: 0.6,
    Level7: 0.7,
    Level8: 0.8,
    Level9: 0.9,
  };

  return overlayLevels[overlayLevel];
};

/**
 * @return {html}
 */
const SectionImageBanner = ({ component }) => {
  const placeholderImageStyle = {
    backgroundImage: `url(${placeholder})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };
  const strapiBackEndUploadsUrl = getStrapiBackEndUrl(StrapiBackEndUrlKind.Base);

  return (
    <section
      className="sw-section-image-banner"
      id={component.anchorId || `section-image-banner-${component.id}`}
    >
      <div className="container">
        {
          (component.techbar) && (
            <div className="image-overlay image-overlay-techbar" />
          )
        }

        <div className="image-overlay" style={{ opacity: overlayOpacity(component.imageOverlay !== 'None' ? component.imageOverlay : 'Level1') }} />

        <div className="image-container">
          {
            !component?.image ? (
              <div className="image" style={placeholderImageStyle} />
            ) : (
              <picture>
                <source srcSet={`${strapiBackEndUploadsUrl}${component?.image?.url}`} media="(min-width: 2560px)" />
                <source srcSet={`${strapiBackEndUploadsUrl}${component?.image?.formats?.xxl?.url || component?.image?.formats?.xl?.url || component?.image?.formats?.large?.url || component?.image?.url}`} media="(min-width: 1920px)" />
                <source srcSet={`${strapiBackEndUploadsUrl}${component?.image?.formats?.xl?.url || component?.image?.formats?.large?.url || component?.image?.url}`} media="(min-width: 1024px)" />
                <source srcSet={`${strapiBackEndUploadsUrl}${component?.image?.formats?.large?.url || component?.image?.formats?.medium?.url || component?.image?.url}`} media="(min-width: 960px)" />
                <source srcSet={`${strapiBackEndUploadsUrl}${component?.image?.formats?.medium?.url || component?.image?.formats?.small?.url || component?.image?.url}`} media="(min-width: 750px)" />
                <source srcSet={`${strapiBackEndUploadsUrl}${component?.image?.formats?.small?.url}`} media="(min-width: 360px)" />
                <img className="image" src={`${strapiBackEndUploadsUrl}${component?.image?.formats?.xs?.url}`} alt={component?.image?.alternativeText} />
              </picture>
            )
          }
        </div>

        <div className="banner">
          <div className="content-container">
            <div className="content strapi-markdown">
              <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
                {component.content}
              </ReactMarkdown>
            </div>
          </div>
        </div>
      </div>

    </section>
  );
};

export default SectionImageBanner;
