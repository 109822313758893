import React from 'react';

import Image from '../Image';
import DynamicLink from '../DynamicLink';
import { StrapiBackEndUrlKind, getStrapiBackEndUrl } from '../../utils';

import './CardPublication.scss';

const OverlayComponent = ({ title, summary }) => (
  <div className="overlay-component">
    <div className="h6">{title}</div>
    <div>{summary}</div>
  </div>
);

/**
 * @return {html}
 */
const CardPublication = ({ publication }) => {
  const publicationAttributes = publication.attributes;
  const publicationImageAttributes = publicationAttributes.image?.data?.attributes;
  return (
    <div className="sw-card-publication">
      <DynamicLink
        key={publication.id}
        to={`${getStrapiBackEndUrl(StrapiBackEndUrlKind.Base)}${publicationAttributes.file.url}`}
      >
        <Image
          alternativeText={publicationImageAttributes?.alternativeText}
          aspectRatio="9:13"
          caption={publicationImageAttributes?.caption}
          imageAttributes={publicationImageAttributes?.formats.small || publicationImageAttributes?.formats.thumbnail}
          overlayComponent={<OverlayComponent title={publicationAttributes.title} summary={publicationAttributes.summary} />}
        />
      </DynamicLink>
    </div>
  );
};

export default CardPublication;
