import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import qs from 'qs';
import ApiService from '../../../services/ApiService';
import Statuses from '../../types/Statuses';

interface INewsItems {
  newsItems: any[];
  status: string;
  error: string;
}

const initialState: INewsItems = {
  newsItems: [],
  status: Statuses.idle,
  error: null,
};

export const fetchNewsItems = createAsyncThunk('newsItems/fetchNewsItems', async () => {
  const todayDate = moment().format('YYYY-MM-DD');

  const query = qs.stringify({
    filters: {
      visibleFrom: {
        $lte: todayDate,
      },
    },
    populate: [
      'contactPerson',
      'contactPerson.address',
      'contactPerson.image',
      'image',
      'team',
      'team.address',
      'team.people',
      'team.people.address',
      'team.people.image',
    ],
    sort: 'date:desc',
  },
  {
    encodeValuesOnly: true,
  });

  const url = `/news-items?${query}`;
  const response = await ApiService.get(url);
  return response.data;
});

export const NewsItemsSlice = createSlice({
  name: 'newsItems',
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(fetchNewsItems.pending, (state) => {
        state.status = Statuses.loading;
      })
      .addCase(fetchNewsItems.fulfilled, (state, action) => {
        state.status = Statuses.succeeded;
        // Add fetched news items to the store
        state.newsItems = action.payload.data;
      })
      .addCase(fetchNewsItems.rejected, (state, action) => {
        state.status = Statuses.failed;
        state.error = action.error.message;
      });
  },
});

export const selectNewsItems = state => state.newsItems.newsItems;

export const selectRecentNewsItems = count => state => selectNewsItems(state).slice(0, count);

export const selectNewsItemBySlug = slug => state => selectNewsItems(state).find(obj => obj.slug === slug);

export default NewsItemsSlice.reducer;
