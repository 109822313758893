import React from "react";
import "./CardCustomer.scss";
import { StrapiBackEndUrlKind, getStrapiBackEndUrl } from '../../utils';

/**
 * @return {html}
 */
const CardCustomer = ({ card }) => {
  const cardImageAttributes = card.image.data.attributes;
  return (
    <div className="sw-card-customer">
      <img
        alt={cardImageAttributes.alternativeText}
        src={`${getStrapiBackEndUrl(StrapiBackEndUrlKind.Base)}${cardImageAttributes.url}`}
        title={cardImageAttributes.caption}
      />
    </div>
  );
};

export default CardCustomer;
