import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import qs from 'qs';
import { scrollToHash } from '../../global-effects';

// Components
import CookieConsentBox from '../CookieConsentBox';
import PageViewTracker from '../PageViewTracker';
import HeaderHelmet from '../HeaderHelmet';
import Loader from '../Loader';
import JobPageContent from '../JobPageContent/JobPageContent';
import PageFooter from '../PageFooter/PageFooter';
import PageHeader from '../PageHeader/PageHeader';

// Services
import ApiService from '../../services/ApiService';

// Store
import { getJobQueryPopulate, selectJobBySlug } from '../../state/store/slices/jobsSlice';
import { selectSite } from '../../state/store/slices/mainMenuSlice';
import { useAppSelector } from '../../state/hooks';

// Utils
import { formatUrl, personToTeam } from '../../utils';

import './JobPage.scss';

const getJobsBySlug = (pageSlug) => {
  const query = qs.stringify({
    filters: {
      slug: {
        $eq: `${pageSlug}`,
      },
    },
    populate: getJobQueryPopulate(),
  });

  return ApiService.get(`/jobs?${query}`);
};

const adjustJobAttributes = (jobAttributes) => {
  const header = {
    title: jobAttributes.title,
    subTitle: null,
    image: null,
    imageOverlay: 'None',
    theme: 'Syncwork',
  };

  const adjustedJobAttributes = {
    header,
    ...jobAttributes,
    team: personToTeam(jobAttributes.contactPerson.data),
  };

  return adjustedJobAttributes;
};

const JobPage = ({ useCareerDomainRedirect }) => {
  // @ts-ignore
  const { slug } = useParams();
  const site = useAppSelector(selectSite);
  const siteAttributes = site?.attributes;
  const scrolledRef = useRef(false);
  const { hash } = useLocation();

  const [jobAttributes, setJobAttributes] = useState(null);
  const setJobAttributesAndPossiblyScroll = (jobAttributes2) => {
    setJobAttributes(jobAttributes2);
    scrollToHash(scrolledRef, hash);
  };

  // retrieve the job from the store, this should have been previously fetched when the jobs list was loaded
  const storeJobAttributes = useAppSelector(selectJobBySlug(slug));

  useEffect(() => {
    if (useCareerDomainRedirect) {
      window.location.href = formatUrl(`https://karriere.syncwork.de/jobs/${slug}`);
      return;
    }

    if (storeJobAttributes) setJobAttributesAndPossiblyScroll(storeJobAttributes);
    else {
      getJobsBySlug(slug).then(response => {
        const responseJobAttributes = response.data?.data[0]?.attributes;
        if (responseJobAttributes) setJobAttributesAndPossiblyScroll(responseJobAttributes);
      });
    }
  }, [slug]);

  // show loader as long there is no site menu
  if (!site) {
    return (
      <div className="sw-page">
        <Loader />
      </div>
    );
  }

  if (!jobAttributes) {
    return (<></>);
  }

  const adjustedJobAttributes = adjustJobAttributes(jobAttributes);

  return (
    <>
      <CookieConsentBox googleTagManagerId={siteAttributes.googleTagManagerId} />
      <PageViewTracker />
      <HeaderHelmet header={adjustedJobAttributes.header} site={site} meta={adjustedJobAttributes.meta} />
      <div className="sw-page">
        <PageHeader pageAttributes={adjustedJobAttributes} />
        <JobPageContent jobAttributes={adjustedJobAttributes} />
        <PageFooter teamAttributes={adjustedJobAttributes.team?.data?.attributes} />
      </div>
    </>
  );
};

export default JobPage;
