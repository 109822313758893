import React from 'react';

import ButtonGroup from '../ButtonGroup/ButtonGroup';
import ButtonLink from '../ButtonLink/ButtonLink';
import CardQuote from '../CardQuote';
import { StrapiBackEndUrlKind, getStrapiBackEndUrl, camelToKebab } from '../../utils';

import './SectionImageSplit.scss';

interface IProps {
  component: any;
}

/**
 * @return {html}
 */
const SectionImageSplit = ({ component }: IProps) => {
  const variant = camelToKebab(component.variant);
  const classes = `sw-section-image-split section-${variant}`;

  const fixedImageBgStyle = {
    width: '100%',
    height: '100%',
    backgroundImage: 'none',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };

  const imageBgStyle = {
    backgroundImage: 'none',
  };

  const actualImage = component.image?.formats?.large || component.image?.formats?.medium || component.image;
  let actualImageBgStyle : any = imageBgStyle;

  if (actualImage) {
    const isFixedSizeImage = actualImage === component.image;

    if (isFixedSizeImage) {
      actualImageBgStyle = fixedImageBgStyle;
      actualImageBgStyle.backgroundSize = `${component.image.width}px ${component.image.height}px`;
    }

    const bgImageUrl = `${getStrapiBackEndUrl(StrapiBackEndUrlKind.Base)}${actualImage.url}`;
    actualImageBgStyle.backgroundImage = `url(${bgImageUrl})`;
  }

  return (
    <section
      className={classes}
      id={component.anchorId || `section-image-split-${component.id}`}
    >
      <div className="split">
        <div className="split-left">
          <div className={`image color-bg-${variant}-dark`} style={actualImageBgStyle} />
          <div className={`drop-shadow color-bg-${variant}-default`} />
        </div>
        <div className={`split-right ${component.collapseTop ? 'collapse-top' : ''} ${component.collapseBottom ? 'collapse-bottom' : ''}`}>
          {
            component.quote && (
              <div className="content">
                <CardQuote card={component.quote} />
              </div>
            )
          }

          <div className="content text-center">
            {
              component.title && (
                <h2>{component.title}</h2>
              )
            }

            {
              component.text && (
                <p>{component.text}</p>
              )
            }

            {
              (component.linkText && component.linkUrl) && (
                <ButtonGroup>
                  <ButtonLink text={component.linkText} to={component.linkUrl} variant={variant} />
                </ButtonGroup>
              )
            }
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionImageSplit;
