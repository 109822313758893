import React from 'react';

import Image from '../Image';

import './CardLocation.scss';

const OverlayComponent = ({ text }) => (
  <div className="overlay-component">
    <div>{text}</div>
  </div>
);

/**
 * @return {html}
 */
const CardLocation = ({ locationAttributes }) => (
  <div className="sw-card-location">
    <Image
      aspectRatio="16:7"
      elevated={false}
      imageAttributes={locationAttributes.image?.data?.attributes.formats.small}
      overlayComponent={<OverlayComponent text={locationAttributes.city} />}
    />
    <div className="location-details">
      <p>
        {locationAttributes.addressLine1}<br />
        {locationAttributes.postcode} {locationAttributes.city}<br />
        <a className="sw-link" href={`tel:${locationAttributes.phoneBusiness}`}>{locationAttributes.phoneBusiness}</a>
      </p>
    </div>
  </div>
);

export default CardLocation;
