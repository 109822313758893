import React from 'react';
import { Link } from 'react-router-dom';

import Icon from '../Icon';
import {
  StrapiBackEndUrlKind,
  getStrapiBackEndUrl,
  formatUrl,
  isExternalLink,
} from '../../utils';

import './CardFocus.scss';

/**
 * @return {html}
 */
const CardFocus = ({ card, theme = 'default' }) => {
  const icon = card.file ? 'Download' : 'ExternalLink';
  let url;
  if (card.file) {
    url = `${getStrapiBackEndUrl(StrapiBackEndUrlKind.Base)}${card.file.url}`;
  } else if (card.url) {
    url = card.url;
  } else {
    return (<></>);
  }

  const isExternal = isExternalLink(url);
  const formattedUrl = formatUrl(url);
  const to = isExternal ? { pathname: formattedUrl } : formattedUrl;

  return (
    <Link to={to} target={isExternal ? '_blank' : '_self'}>
      <div className={`sw-card-focus sw-card-focus-${theme}`}>
        <div className="h6">
          {card.title}
        </div>
        <div className="link-icon">
          <Icon name={icon} width="1.5rem" height="1.5rem" />
        </div>
      </div>
    </Link>
  );
};

export default CardFocus;
