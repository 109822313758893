import React from 'react';

import CardIconCaption from '../CardIconCaption';
import SectionWrapper from '../SectionWrapper';
import { camelToKebab } from '../../utils';

import './SectionIconCaption.scss';

interface IProps {
  component: any;
}

/**
 * @return {html}
 */
const SectionIconCaption = ({ component }: IProps) => {
  const theme = camelToKebab(component.theme);

  return (
    <SectionWrapper
      classes={`sw-section-icon-caption sw-section-icon-caption-${theme}`}
      collapseBottom={component.collapseBottom}
      collapseTop={component.collapseTop}
      id={component.anchorId || `section-icon-caption-${component.id}`}
    >
      <div className="grid-1fr-1fr2fr grid-gap">
        <div>
          {
            component.title && (
              <>
                <h2 className="h5">{component.title}</h2>
                {
                  component.subTitle && (<div className="h6">{component.subTitle}</div>)
                }
              </>
            )
          }
        </div>

        <div className="grid-1x1fr-2x1fr-4x1fr">
          {
            component.cards.map(iconCard => (<CardIconCaption key={iconCard.id} card={iconCard} />))
          }
        </div>
      </div>
    </SectionWrapper>
  );
};

export default SectionIconCaption;
