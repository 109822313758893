import React from 'react';

import { useTranslation } from 'react-i18next';
import ContactPerson from '../ContactPerson/ContactPerson';
import SectionWrapper from '../SectionWrapper';

import './ContactTeam.scss';

/**
 * @return {html}
 */
const ContactTeam = ({ teamAttributes }) => {
  const { t } = useTranslation();

  const readProperty = (propertyName) => {
    let value = teamAttributes[propertyName];

    if (!value && teamAttributes.people?.data) value = teamAttributes.people.data[0]?.attributes[propertyName];

    return value;
  };

  const address = readProperty('address');
  const addressAttributes = address?.data?.attributes;
  const phoneBusiness = readProperty('phoneBusiness');
  const phoneMobile = readProperty('phoneMobile');
  const email = readProperty('email');

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const contactTeamTitleTKey = () => {
    if (teamAttributes.people?.data?.length === 1) {
      return (teamAttributes.people.data[0].attributes.gender === 'Female') ? 'contact-team.your-contact-f' : 'contact-team.your-contact-m';
    }

    return 'contact-team.your-contacts';
  };

  console.log(teamAttributes);

  return (
    <SectionWrapper>
      <div className="sw-team">
        <header>
          <div className="team-heading">{t(contactTeamTitleTKey())}</div>
        </header>

        <div className="persons">
          {
            teamAttributes.people?.data?.map(person => (
              <ContactPerson key={person.id} personAttributes={person.attributes} />
            ))
          }
          <div className="contact">
            <div className="font-weight-700">
              {teamAttributes.name}
            </div>
            {
              teamAttributes.description && (
                <div>
                  {teamAttributes.description}
                </div>
              )
            }
            {
              addressAttributes && (
                <div className="mb-1">
                  <div>
                    {addressAttributes.addressLine1}
                  </div>
                  {
                    addressAttributes.addressLine2 && (
                      <div>
                        {addressAttributes.addressLine2}
                      </div>
                    )
                  }
                  <div>
                    {addressAttributes.postcode} {addressAttributes.city}
                  </div>
                </div>
              )
            }
            {
              phoneBusiness && (
                <div>
                  <a className="sw-link" href={`tel:${phoneBusiness}`} rel="noreferrer nofollow" target="_blank">
                    {phoneBusiness}
                  </a>
                </div>
              )
            }
            {
              phoneMobile && (
                <div>
                  <a className="sw-link" href={`tel:${phoneMobile}`} rel="noreferrer nofollow" target="_blank">
                    {phoneMobile}
                  </a>
                </div>
              )
            }
            {
              email && (
                <div>
                  <a className="sw-link" href={`mailto:${email}`} rel="noreferrer nofollow" target="_blank">
                    {email}
                  </a>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default ContactTeam;
