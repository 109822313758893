import React from 'react';
import Image from '../Image';
import './ContactPerson.scss';

/**
 * @return {html}
 */
const ContactPerson = ({ personAttributes }) => {
  const imageAttributes = personAttributes.image.data.attributes;
  return (
    <div className="sw-contact-person">
      <Image
        alternativeText={imageAttributes?.alternativeText}
        caption={imageAttributes?.caption}
        imageAttributes={imageAttributes?.formats.small || imageAttributes?.formats.thumbnail}
      />
    </div>
  );
};

export default ContactPerson;
