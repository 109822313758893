import config from '../App.config';

enum StrapiBackEndUrlKind {
  Base = 1,
  Api,
  Uploads,
}

const getStrapiBackEndBaseUrl = () => {
  let baseUrl = '';

  if (config.api.host) {
    if (config.api.port) baseUrl = [config.api.scheme, '://', config.api.host, ':', config.api.port].join('');
    else baseUrl = [config.api.scheme, '://', config.api.host].join('');
  }

  if (config.api.relativeUrl) baseUrl += config.api.relativeUrl;

  return baseUrl;
};

const getStrapiBackEndUrl = (kind: StrapiBackEndUrlKind) => {
  let baseUrl = getStrapiBackEndBaseUrl();

  switch (kind) {
    case StrapiBackEndUrlKind.Api:
      baseUrl += '/api';
      break;
    case StrapiBackEndUrlKind.Uploads:
      baseUrl += '/uploads';
      break;
    default:
      break;
  }

  return baseUrl;
};

export { StrapiBackEndUrlKind, getStrapiBackEndUrl };
