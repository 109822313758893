import React from 'react';
import { useTranslation } from 'react-i18next';

import JobPageMetaIcon from './JobPageMetaIcon';

import './JobPageMeta.scss';

/**
 * @return {html}
 */
const JobPageMeta = ({ contractTypes, locations }) => {
  const { t } = useTranslation();
  const contractTypeNames = [];
  contractTypes.map(contractType => contractTypeNames.push(t(`contract-types.${contractType.attributes.key}`)));

  const cities = [];
  locations.map(location => cities.push(location.attributes.city));

  return (
    <div className="sw-job-page-meta">
      <div>
        <JobPageMetaIcon name="Contract" />
        {contractTypeNames.join(', ')}
      </div>
      <div>
        <JobPageMetaIcon name="Location" />
        {cities.sort().join(', ')}
      </div>
    </div>
  );
};

export default JobPageMeta;
