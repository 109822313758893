import React from 'react';
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';

import { camelToKebab } from '../../utils';

// store
import { selectSiteAttributes } from '../../state/store/slices/mainMenuSlice';
import { useAppSelector } from '../../state/hooks';

import './CardEventProgramItem.scss';

/**
 * @return {html}
 */
const CardEventProgramItem = ({ item, theme = null }) => {
  if (theme === 'default') {
    const siteTheme = useAppSelector(selectSiteAttributes)?.theme;
    // eslint-disable-next-line no-param-reassign
    theme = camelToKebab(siteTheme);
  }
  const { t } = useTranslation();
  const time = item.time.split(':').slice(0, 2).join(':');

  return (
    <div className={`sw-card-event-program-item sw-card-event-program-item-${theme}`}>
      <div>
        {
          item.date && (
            <div>
              <span className="date">{new Date(item.date).toLocaleDateString('de-DE')}</span>
            </div>
          )
        }
        <span className="time">{`${time} ${t('common.hours')}`}</span>
      </div>
      <div className="description">
        <div className="title">
          {item.title}
        </div>
        {
          item.description && (
            <div className="strapi-markdown">
              <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
                {item.description}
              </ReactMarkdown>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default CardEventProgramItem;
