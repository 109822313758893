/* eslint-disable react/no-danger */
import React from 'react';

/*
 *  https://locutus.io/
 *  Only allow <br> tags in title & subTitle to allow content editors add a new line, yet maintain XXS security
 */
import * as stripTags from 'locutus/php/strings/strip_tags';

import { StrapiBackEndUrlKind, getStrapiBackEndUrl } from '../../utils';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import ButtonCTA from '../ButtonCTA';
import SvgLogoTechbarGradient from '../Logos/SvgLogoTechbarGradient';
import PageContent from '../PageContent';

import './PageHeader.scss';

// store
import { selectSiteAttributes } from '../../state/store/slices/mainMenuSlice';
import { useAppSelector } from '../../state/hooks';

const placeholder = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAALCAYAAAAeEY8BAAAACXBIWXMAAAsTAAALEwEAmpwYAAACy0lEQVQ4jZ2T6VNSYRjFQwVkEyQEDDU0UlMrsyytccqZypmm6e9paswxI/fEFWSRTRCRRQS0XDOX+q' +
  '9O571+qG8uH565986993l+5zznvXZt8w/Oq5LCKUrzxyjL/YIic4Dy1C5Uqz+gWdmCNl6EbjmPiugGKwcdr+JZGytCndiCKrkNZWoP8uwBSnNHKMmfQFY8/df/vOGy4u8zgI0jNvkpNVNzuBhcwUGGyDoqQxkYgykYl1jBNAx81kcIE8tLkAJCkd6XBJRQiKxwW' +
  'QBSi5+VmX2o2UwX34SBao0cVBVYg9mXgMW7AitLXM3+VZgIUxleh164kfguuaZYP6SQY0nQpQCE/XL+LJqIZgbabKJSiz+JG4tx1CxEUTcflqp2IQKbJ4ZqbwJmQlwXEHRLI1ZBB8vo5OUBSK3gDtXJHamZKZyFlcNrOMjOoQ0zS3C4/Gc1HUD9XAg33cuwCWcI' +
  'aqQLOq5NxfzIc1cAENRK7lDLJkaqt1JZrSfOQWE0cuidyUW0Trilapn0oNnlg2M2BDsBbQQ1Mw96OqdhD4XIAQFkFwbgx2L/5QxfBQNVRUtrfKtomI+gyRVA24QH7aNz6BiewUPWA97fEyAEu81v7FxFdSgL48omtOk9KNmr9LIAcv6kWtuFgeGzBjNUFkfTTBB' +
  '3qbxjZA5PnFPoHvqGp6wu3neOzKKd71r4jcMdQ+1SGiZxaiiiXASxcHLxFQi7RHo1azvcZQG2QAoOhq51yo+OsQV0f3GhZ3AcLwZG0TswhueDE3jmdOEx392nC83zUdh5UiyxAvQUcWUALVNcFc2jzpdE82xYUthFy8XAl/3D6PvoZH3F6/4R9AoIvns06UUbs3' +
  'CLK6tmECspQnUVAGX2kCnehiWygXrvKtpobee4Gz1U/+rTGN58cOLd+8+sIbzlfZ9wgy50jzMf00E0Mgc2htfIU6QWR/E/gL84FlRj5pA0LAAAAABJRU5ErkJggg==';

const overlayOpacity = (overlayLevel) => {
  const overlayLevels = {
    None: 0,
    Level1: 0.1,
    Level2: 0.2,
    Level3: 0.3,
    Level4: 0.4,
    Level5: 0.5,
    Level6: 0.6,
    Level7: 0.7,
    Level8: 0.8,
    Level9: 0.9,
  };

  return overlayLevels[overlayLevel];
};

/**
 * @return {html}
 */
const PageHeader = ({ pageAttributes }: any) => {
  console.log(pageAttributes);
  const { header } = pageAttributes || {};
  const imageAttributes = header?.image?.data?.attributes;
  const siteAttributes = useAppSelector(selectSiteAttributes);

  const placeholderImageStyle = {
    backgroundImage: `url(${placeholder})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };

  if (!siteAttributes) {
    return <></>;
  }

  const theme = siteAttributes.theme?.toLowerCase() || 'syncwork';
  const strapiBackEndUploadsUrl = getStrapiBackEndUrl(StrapiBackEndUrlKind.Base);

  if (!header) {
    return <>no header</>;
  }

  return (
    <>
      <header className={`sw-page-header sw-page-header-${theme}`}>
        {
          (theme === 'techbar') ? (
            <></>
          ) : (
            <div className="social-media">
              <div>
                <ButtonGroup>
                  <ButtonIcon to="https://www.instagram.com/syncworkag" name="Instagram" title="Instagram" height="2rem" width="2rem" />
                  <ButtonIcon to="https://de.linkedin.com/company/syncworkag" name="LinkedIn" title="LinkedIn" height="2rem" width="2rem" />
                  <ButtonIcon to="https://www.xing.com/pages/syncworkag" name="Xing" title="Xing" height="2rem" width="2rem" />
                  <ButtonIcon to="https://www.youtube.com/channel/UClv0MdRzKlw3pzVIMAmRcKQ" name="YouTube" title="YouTube" height="2rem" width="2rem" />
                </ButtonGroup>
              </div>
            </div>
          )
        }

        <div className="container">
          <div className="polygon">
            {
              (theme === 'techbar') && (
                <div className="image-overlay image-overlay-techbar" />
              )
            }
            <div className="image-overlay" style={{ opacity: overlayOpacity(header.imageOverlay !== 'None' ? header.imageOverlay : 'Level1') }} />

            <div className="image-container">
              {
                (!header?.image || !imageAttributes) ? (
                  <div className="image" style={placeholderImageStyle} />
                ) : (
                  <picture>
                    <source srcSet={`${strapiBackEndUploadsUrl}${imageAttributes.url}`} media="(min-width: 2560px)" />
                    <source srcSet={`${strapiBackEndUploadsUrl}${imageAttributes?.formats?.xxl?.url || imageAttributes?.formats?.xl?.url || imageAttributes?.formats?.large?.url || imageAttributes?.url}`} media="(min-width: 1920px)" />
                    <source srcSet={`${strapiBackEndUploadsUrl}${imageAttributes?.formats?.xl?.url || imageAttributes?.formats?.large?.url || imageAttributes?.url}`} media="(min-width: 1024px)" />
                    <source srcSet={`${strapiBackEndUploadsUrl}${imageAttributes?.formats?.large?.url || imageAttributes?.formats?.medium?.url || imageAttributes?.url}`} media="(min-width: 960px)" />
                    <source srcSet={`${strapiBackEndUploadsUrl}${imageAttributes?.formats?.medium?.url || imageAttributes?.formats?.small?.url || imageAttributes?.url}`} media="(min-width: 750px)" />
                    <source srcSet={`${strapiBackEndUploadsUrl}${imageAttributes?.formats?.small?.url}`} media="(min-width: 360px)" />
                    <img className="image" src={`${strapiBackEndUploadsUrl}${imageAttributes?.formats?.xs?.url}`} alt={imageAttributes?.alternativeText} />
                  </picture>
                )
              }
            </div>
            <div className="content-container-absolute">
              {
                (theme === 'techbar') ? (
                  <div className="content-container" title={imageAttributes?.caption}>
                    <div className="content">
                      <div className="logo">
                        <SvgLogoTechbarGradient />
                      </div>

                      <div>
                        <h1 className="title" dangerouslySetInnerHTML={{ __html: stripTags(header.title, '<br>') }} />
                      </div>
                      {
                        header.subTitle && (
                          <div className="subtitle h2" dangerouslySetInnerHTML={{ __html: stripTags(header.subTitle, '<br>') }} />
                        )
                      }

                      {
                        header.cta && (
                          <div className="cta">
                            <ButtonCTA
                              text={header.cta.linkText}
                              to={header.cta.linkUrl}
                              icon={header.cta.icon}
                              theme={header.cta.theme}
                            />
                          </div>
                        )
                      }
                      <PageContent pageAttributes={pageAttributes} useHeaderContent isEmbeddedInHeader />
                    </div>
                  </div>
                ) : (
                  <div className="content-container" title={header?.image?.caption}>
                    <div>
                      <h1 dangerouslySetInnerHTML={{ __html: stripTags(header.title, '<br>') }} />
                      {
                        header.subTitle && (
                          <div className="h3" dangerouslySetInnerHTML={{ __html: stripTags(header.subTitle, '<br>') }} />
                        )
                      }

                      {
                        header.cta && (
                          <ButtonCTA
                            text={header.cta.linkText}
                            to={header.cta.linkUrl}
                            icon={header.cta.icon}
                            theme={header.cta.theme}
                          />
                        )
                      }
                      <PageContent pageAttributes={pageAttributes} useHeaderContent isEmbeddedInHeader />
                    </div>
                  </div>
                )
              }

            </div>
          </div>
        </div>
      </header>
      <PageContent pageAttributes={pageAttributes} useHeaderContent />
    </>
  );
};

export default PageHeader;
