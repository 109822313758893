import React from 'react';

import DynamicLink from '../DynamicLink';
import Image from '../Image';

import './CarouselSlideGeneric.scss';

/**
 * @return {html}
 */
const CarouselSlideGeneric = ({ slide }) => (
  <div className="sw-carousel-slide-generic">
    <DynamicLink
      key={slide.id}
      to={slide.url}
    >
      <Image
        alternativeText={slide.image?.alternativeText}
        aspectRatio="21:9"
        caption={slide.image?.caption}
        imageAttributes={slide.image?.formats.small || slide.image?.formats.thumbnail}
      />
      <div className="details">
        <div className="font-weight-600">
          <span>&nbsp;</span>
        </div>
        <div className="h6">{slide.title}</div>
      </div>
    </DynamicLink>
    <div className="content">
      {slide.content}
    </div>
  </div>
);

export default CarouselSlideGeneric;
