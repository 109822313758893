import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import qs from 'qs';
import { isEmpty } from 'lodash-es';
import { scrollToHash } from '../../global-effects';

// Components
import CookieConsentBox from '../CookieConsentBox';
import PageViewTracker from '../PageViewTracker';
import HeaderHelmet from '../HeaderHelmet';
import Loader from '../Loader';
import NewsItemPageContent from '../NewsItemPageContent';
import PageFooter from '../PageFooter/PageFooter';
import PageHeader from '../PageHeader/PageHeader';
import { personToTeam } from '../../utils';

// Services
import ApiService from '../../services/ApiService';

// Store
import { selectSite } from '../../state/store/slices/mainMenuSlice';
import { useAppSelector } from '../../state/hooks';

import './NewsItemPage.scss';

const getNewsItemBySlug = (pageSlug) => {
  const query = qs.stringify({
    filters: {
      slug: {
        $eq: `${pageSlug}`,
      },
    },
    populate: [
      'meta',
      'image',
      'contactPerson',
      'contactPerson.address',
      'contactPerson.image',
      'team',
      'team.address',
      'team.people',
      'team.people.address',
      'team.people.image',
      'additionalLinks',
    ],
  });

  return ApiService.get(`/news-items?${query}`);
};

const adjustNewsItemAttributes = (newsItemAttributes) => {
  const header = {
    title: newsItemAttributes.title,
    subTitle: newsItemAttributes.subTitle,
    image: newsItemAttributes.image,
    imageOverlay: newsItemAttributes.imageOverlay,
    theme: 'Syncwork',
  };

  const adjustedNewsItemAttributes = {
    header,
    ...newsItemAttributes,
  };

  // convert any instances of team === {} to null, this occurs when a team is deleted
  if (isEmpty(adjustedNewsItemAttributes.team)) newsItemAttributes.team = null;

  if (adjustedNewsItemAttributes.contactPerson?.attributes) {
    adjustedNewsItemAttributes.team = personToTeam(newsItemAttributes.contactPerson);
  }

  return adjustedNewsItemAttributes;
};

const NewsItemPage = () => {
  // @ts-ignore
  const { slug } = useParams();
  const site = useAppSelector(selectSite);
  const siteAttributes = site?.attributes;
  const scrolledRef = useRef(false);
  const { hash } = useLocation();

  const [newsItemAttributes, setNewsItemAttributes] = useState(null);
  const setNewsItemAttributesAndPossiblyScroll = (newsItemAttributes2) => {
    setNewsItemAttributes(newsItemAttributes2);
    scrollToHash(scrolledRef, hash);
  };

  useEffect(() => {
    getNewsItemBySlug(slug).then(response => {
      const responseNewsItemAttributes = response.data?.data[0]?.attributes;
      if (responseNewsItemAttributes) {
        setNewsItemAttributesAndPossiblyScroll(responseNewsItemAttributes);
      }
    });
  }, [slug]);

  if (!newsItemAttributes) {
    return (<></>);
  }

  // show loader as long there is no site menu
  if (!site) {
    return (
      <div className="sw-page">
        <Loader />
      </div>
    );
  }

  const adjustedNewsItemAttributes = adjustNewsItemAttributes(newsItemAttributes);

  return (
    <>
      <CookieConsentBox googleTagManagerId={siteAttributes.googleTagManagerId} />
      <PageViewTracker />
      <HeaderHelmet header={adjustedNewsItemAttributes.header} site={site} meta={adjustedNewsItemAttributes.meta} />
      <div className="sw-page">
        <PageHeader pageAttributes={adjustedNewsItemAttributes} />
        <NewsItemPageContent newsItemAttributes={adjustedNewsItemAttributes} />
        <PageFooter teamAttributes={adjustedNewsItemAttributes.team?.data?.attributes} />
      </div>
    </>
  );
};

export default NewsItemPage;
