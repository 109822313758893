const personToTeam = person => {
  const teamAttributes = {
    name: person.attributes.name,
    people: {
      data: [
        person,
      ],
    },
  };

  return {
    data: {
      attributes: teamAttributes,
    },
  };
};

export default personToTeam;
