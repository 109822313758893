import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import Brand from "../Brand/Brand";
import DynamicLink from "../DynamicLink";
import SvgIconSyncwork from "../Logos/SvgIconSyncwork";
import SvgLogoTechbar from "../Logos/SvgLogoTechbar";

// store
import { selectSite } from "../../state/store/slices/mainMenuSlice";
import { useAppSelector } from "../../state/hooks";

import "./MainMenu.scss";

const MainMenuItems = ({ menuItems, toggleActiveMenu, active }) => (
  <>
    {menuItems.map((menuItem) => (
      <div key={menuItem.id}>
        {menuItem.url ? (
          menuItem.url === "https://syncwork.de/" || menuItem.url === "https://www.syncwork.de/" ? (
            <a href="https://syncwork.de/">
              {/* want google to follow the links to the homepage (Syncwork AG), <DynamicLink> + "https://" = rel="nofollow" */}
              <span className="homepage-link">{menuItem.title}</span>
            </a>
          ) : (
            <DynamicLink to={menuItem.url}>
              <span>{menuItem.title}</span>
            </DynamicLink>
          )
        ) : (
          <>
            {menuItem.pages.data.length === 1 && !menuItem.pages.data[0].attributes.hashFragments.length ? (
              // Kontakt
              <DynamicLink key={menuItem.pages.data[0].id} to={menuItem.pages.data[0].attributes.slug}>
                <span>{menuItem.pages.data[0].attributes.menuLabel}</span>
              </DynamicLink>
            ) : (
              <>
                <button className="toggle" onBlur={() => toggleActiveMenu(null)} onClick={() => toggleActiveMenu(menuItem.id)} type="button">
                  <span>{menuItem.title}</span>
                  <i className={menuItem.id === active ? "icon icon-chevron-up" : "icon icon-chevron-down"} />
                </button>
                <div className="menu-sub">
                  <div className={menuItem.id === active ? "drop drop-active" : "drop"}>
                    {menuItem.pages.data.map((page) => {
                      if (page.attributes.hashFragments.length) {
                        // Link with hash (Unternehmen, Karriere, Aktuelles)
                        return page.attributes.hashFragments.map((hashFragment) => {
                          const url = hashFragment.fragment ? `/${page.attributes.slug}#${hashFragment.fragment}` : `/${page.attributes.slug}#root`;

                          return (
                            <DynamicLink key={hashFragment.id} to={url}>
                              <span>{hashFragment.title}</span>
                            </DynamicLink>
                          );
                        });
                      }
                      return (
                        <DynamicLink key={page.id} to={`/${page.attributes.slug}`}>
                          {page.attributes.menuLabel}
                        </DynamicLink>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    ))}
  </>
);

/**
 * @return {html}
 */
const MainMenu = () => {
  const location = useLocation();
  const site = useAppSelector(selectSite);
  const siteAttributes = site?.attributes;
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeMobileMenu, setActiveMobileMenu] = useState(false);

  const toggleMobileMenu = (isActive?) => {
    const mobileMenuState = typeof isActive === "undefined" ? !activeMobileMenu : isActive;
    setActiveMobileMenu(mobileMenuState);
  };

  const toggleActiveMenu = (id) => {
    setTimeout(() => {
      if (id === activeMenu) {
        setActiveMenu(null);
      } else {
        setActiveMenu(id);
      }
    }, 100);
  };

  useEffect(() => {
    toggleMobileMenu(false);
  }, [location]);

  if (!site) {
    return <></>;
  }

  const theme = siteAttributes.theme.toLowerCase();
  console.log(siteAttributes);

  return (
    <nav className={`sw-main-menu ${activeMenu !== null ? "sw-main-menu-active" : ""}`.trim()}>
      <div className="menu-container">
        <div className="menu-brand">
          {theme === "techbar" ? (
            <Link to="/">
              <Brand iconComponent={<SvgLogoTechbar color="#fff" height="1.2rem" />} suffix={null} />
            </Link>
          ) : (
            <Link to="/">
              <Brand iconComponent={<SvgIconSyncwork color="#fff" height="1.5rem" />} suffix={siteAttributes.subDomainKey !== "www" ? siteAttributes.title : null} />
            </Link>
          )}
        </div>

        <div className="menu-top-mobile">
          <div className="toggle">
            <button className={`hamburger hamburger--slider ${activeMobileMenu ? "is-active" : ""}`.trim()} type="button" onClick={() => toggleMobileMenu()}>
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
          </div>
          <div className={`menu-flyout ${activeMobileMenu ? "is-active" : ""}`.trim()}>
            <MainMenuItems menuItems={siteAttributes.menuItems} toggleActiveMenu={toggleActiveMenu} active={activeMenu} />
          </div>
        </div>

        <div className="menu-top-desktop">
          <MainMenuItems menuItems={siteAttributes.menuItems} toggleActiveMenu={toggleActiveMenu} active={activeMenu} />
        </div>
      </div>
    </nav>
  );
};

export default MainMenu;
