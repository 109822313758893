import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonGroup from '../ButtonGroup/ButtonGroup';
import ButtonStandard from '../ButtonStandard/ButtonStandard';
import Image from '../Image';
import YouTubeIcon from '../Icon/custom/YouTubeIcon';

import './YouTubeVideo.scss';

/**
 * @return {html}
 */
const YouTubeVideo = ({ title, videoId, imageAttributes }) => {
  const { t } = useTranslation();

  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showConsentModal, setShowConsentModal] = useState(false);
  const [needsConsent, setNeedsConsent] = useState(true);

  const handleOnloadVideo = ({ target }) => target.classList.add('video-modal-opacity');

  const handleOnClickPlayButton = () => {
    setShowConsentModal(true);
    setNeedsConsent(false);
  };

  const handleOnClickModalAccept = () => {
    setShowVideoModal(true);
    setShowConsentModal(false);
  };
  const handleOnClickModalAbort = () => {
    setNeedsConsent(true);
    setShowConsentModal(false);
  };

  let videoUrl = `https://www.youtube.com/embed/${videoId}`;
  if (typeof window !== 'undefined') {
    videoUrl += `?enablejsapi=1&origin=${window.location.origin}`;
  }

  return (
    <div className="sw-youtube-video">
      {
        needsConsent && (
          <div className="consent-icon">
            <button type="button" onClick={handleOnClickPlayButton}>
              <YouTubeIcon />
            </button>
          </div>
        )
      }

      {
        showConsentModal && (
          <div className="consent-modal">
            <p className="ws-pre-line">{t('consent-modal.video-will-load-from-youtube')}</p>
            <ButtonGroup>
              <ButtonStandard onClick={handleOnClickModalAccept} text={t('common.accept')} variant="pacific-blue" />
              <ButtonStandard onClick={handleOnClickModalAbort} text={t('common.decline')} variant="alabaster" />
            </ButtonGroup>
          </div>
        )
      }

      {
        showVideoModal && (
          <iframe
            title={title}
            onLoad={handleOnloadVideo}
            className="video-modal"
            width="100%"
            height="100%"
            src={videoUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )
      }

      <Image imageAttributes={imageAttributes} />
    </div>
  );
};

export default YouTubeVideo;
