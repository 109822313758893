import React from 'react';

import JobPageMeta from '../JobPageMeta/JobPageMeta';

import './CardJob.scss';

/**
 * @return {html}
 */
const CardJob = ({ jobAttributes }) => (
  <div className="sw-card-job">
    <div className="h6">{jobAttributes.title}</div>
    <JobPageMeta contractTypes={jobAttributes.contractTypes.data} locations={jobAttributes.locations.data} />
  </div>
);

export default CardJob;
