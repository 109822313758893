import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import Image from '../Image';

import './CardImageText.scss';

/**
 * @return {html}
 */
const CardImageText = ({ card }) => {
  const imageAttributes = card.image?.data?.attributes;

  return (
    <div className="sw-card-image-text">
      <div className="image">
        <Image
          alternativeText={imageAttributes?.alternativeText}
          caption={imageAttributes?.caption}
          imageAttributes={imageAttributes?.formats.small || imageAttributes?.formats.thumbnail}
        />
      </div>
      <div className="strapi-markdown">
        <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
          {card.content}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default CardImageText;
