import React from 'react';

import Image from '../Image';

import './CardPersonnelGallery.scss';

/**
 * @return {html}
 */
const CardPersonnelGallery = ({ personAttributes }) => {
  const actualImageAttributes = personAttributes.galleryImage?.data.attributes ?? personAttributes.image?.data.attributes;

  return (
    <div className="sw-card-personnel-gallery">
      <Image
        alternativeText={actualImageAttributes?.alternativeText}
        aspectRatio="3:4"
        caption={actualImageAttributes?.caption}
        imageAttributes={actualImageAttributes?.formats?.small || actualImageAttributes?.formats?.thumbnail || actualImageAttributes}
      />
      <div className="label">
        <div className="name">{personAttributes.name}</div>
        {
          personAttributes.position && (
            <div className="position">{personAttributes.position}</div>
          )
        }
        <div className="organisation">{personAttributes.organisation}</div>
      </div>
    </div>
  );
};

export default CardPersonnelGallery;
