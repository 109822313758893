const baseConfig = {
  api: {
    relativeUrl: '/strapi-backend',
  },
};

const environments = {
  development: {
    api: {
      scheme: 'http',
      host: 'localhost',
      relativeUrl: null,
    },
  },
};

const env = process.env.REACT_APP_ENV || process.env.NODE_ENV;
const envConfig = environments[env];

export default {
  ...(envConfig ?? baseConfig),
};
