import React from 'react';

import CardQuote from '../CardQuote';
import YouTubeVideo from '../YouTubeVideo';

import './CardVideoQuote.scss';

/**
 * @return {html}
 */
const CardVideoQuote = ({ card }) => (
  <div className="sw-card-video-quote">
    <div className="video">
      <YouTubeVideo title={card.title} videoId={card.videoId} imageAttributes={card.image.data.attributes.formats.large} />
    </div>
    <div>
      <CardQuote card={card} />
    </div>
  </div>
);

export default CardVideoQuote;
