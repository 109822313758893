import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import qs from 'qs';
import ApiService from '../../../services/ApiService';
import Statuses from '../../types/Statuses';

interface ISite {
  site: any;
  status: string;
  error: string;
}

const initialState: ISite = {
  site: null,
  status: Statuses.idle,
  error: null,
};

const baseUrl = '/sites';

const getMenuRelatedPopulateItems = () => [
  'menuItems',
  'menuItems.pages',
  'menuItems.pages.hashFragments',
];

const getSiteBySubDomainKey = async (subDomainKey) => {
  const populateBaseItems = [
    'homePage',
    'notFoundPage',
    'legalNoticePage',
    'dataProtectionPage',
    'favicon',
    'useMenuItemsFromAnotherSite',
  ];

  const query = qs.stringify({
    filters: {
      subDomainKey: {
        $eq: subDomainKey,
      },
    },
    populate: [
      ...populateBaseItems,
      ...getMenuRelatedPopulateItems(),
    ],
  });

  return ApiService.get(`${baseUrl}?${query}`);
};

const getSiteById = (id) => {
  const query = qs.stringify({
    filters: {
      id: {
        $eq: id,
      },
    },
    populate: getMenuRelatedPopulateItems(),
  });

  return ApiService.get(`${baseUrl}?${query}`);
};

export const fetchMainMenu = createAsyncThunk('navigation/fetchMainMenu', async (subDomainKey: string) => {
  const response = await getSiteBySubDomainKey(subDomainKey);
  const responseData = response.data.data[0];

  if (responseData.attributes.useMenuItemsFromAnotherSite.data) {
    const secondResponse = await getSiteById(responseData.attributes.useMenuItemsFromAnotherSite.data.id);
    const secondResponseData = secondResponse.data.data[0];

    responseData.attributes.menuItems = secondResponseData.attributes.menuItems;
    delete responseData.attributes.useMenuItemsFromAnotherSite;
  }

  return responseData;
});

export const mainMenuSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMainMenu.pending, (state) => {
        state.status = Statuses.loading;
      })
      .addCase(fetchMainMenu.fulfilled, (state, action) => {
        state.status = Statuses.succeeded;
        // Add fetched mainMenu to the store
        state.site = action.payload;
      })
      .addCase(fetchMainMenu.rejected, (state, action) => {
        state.status = Statuses.failed;
        state.error = action.error.message;
      });
  },
});

export const selectSite = state => state.navigation.site;

export const selectSiteAttributes = state => selectSite(state)?.attributes;

export default mainMenuSlice.reducer;
