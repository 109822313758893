import React, { useState } from 'react';
import ImageGallery from 'react-image-gallery';

// import Icon from '../Icon';
import Image from '../Image';
import Portal from '../Portal/Portal';
import SectionWrapper from '../SectionWrapper';
import { StrapiBackEndUrlKind, getStrapiBackEndUrl, camelToKebab } from '../../utils';

import './SectionImageGallery.scss';

/**
 * @return {html}
 */
const SectionImageGallery = ({ component }) => {
  const theme = camelToKebab(component.theme);
  const strapiBackEndUploadsUrl = getStrapiBackEndUrl(StrapiBackEndUrlKind.Base);
  const imageGalleryPortal = `sw-section-image-gallery-portal-${component.id}`;
  const [showGallery, setShowGallery] = useState(false);
  const [galleryIndex, setGalleryIndex] = useState(0);

  const showImageGallery = index => {
    setGalleryIndex(index);
    setShowGallery(true);
  };

  const hideImageGallery = () => {
    setGalleryIndex(0);
    setShowGallery(false);
  };

  const galleryItems = component.images.map(image => {
    const originalUrl = image?.formats?.large?.url || image?.formats?.medium?.url || image?.formats?.small?.url || image.url;
    const thumbNailUrl = image?.formats?.thumbnail?.url || image.url;

    return {
      original: strapiBackEndUploadsUrl + originalUrl,
      thumbnail: strapiBackEndUploadsUrl + thumbNailUrl,
      description: image.caption,
    };
  });

  return (
    <SectionWrapper
      classes={`sw-section-image-gallery sw-section-image-gallery-${theme}`}
      collapseBottom={component.collapseBottom}
      collapseTop={component.collapseTop}
      id={component.anchorId || `section-image-gallery-${component.id}`}
    >
      {
        showGallery && (
          <Portal id={imageGalleryPortal}>
            <button className="closer hamburger hamburger--slider is-active" type="button" onClick={() => hideImageGallery()}>
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
            <div>
              <ImageGallery
                items={galleryItems}
                lazyLoad
                showFullscreenButton={false}
                showPlayButton={false}
                showThumbnails={false}
                startIndex={galleryIndex}
              />
            </div>
          </Portal>
        )
      }

      <div className={`grid-1fr-1fr2fr grid-gap ${!component.title ? 'untitled' : ''}`.trim()}>
        <div>
          {
            component.title && (
              <>
                <h2 className="h5">{component.title}</h2>
                {
                  component.subTitle && (<div className="h6">{component.subTitle}</div>)
                }
              </>
            )
          }
        </div>
        <div className="grid-1x1fr-2x1fr-3x1fr-4x1fr">
          {
            component.images.data.map((image, index) => {
              const imageAttributes = image?.attributes;
              const orientation = (imageAttributes.width > imageAttributes.height) ? 'landscape' : 'portrait';
              const aspectRatio = (orientation === 'landscape') ? '4:3' : '3:4';

              return (
                <div key={`image-${image.id}`} className="image-gallery-square">
                  <button
                    type="button"
                    className={`slide ${orientation}`}
                    onClick={() => showImageGallery(index)}
                  >
                    <Image
                      alternativeText={imageAttributes?.alternativeText}
                      aspectRatio={aspectRatio}
                      caption={imageAttributes?.caption}
                      elevated={false}
                      imageAttributes={imageAttributes?.formats?.small || imageAttributes}
                    />
                  </button>
                </div>
              );
            })
          }
        </div>
      </div>
    </SectionWrapper>
  );
};

export default SectionImageGallery;
