import React from 'react';

import './ReifegradEvaluationChart.scss';

/**
 * @return {html}
 */
const ReifegradEvaluationChart = ({ segments = [] }) => {
  // useEffect(() => { console.log('ReifegradEvaluationChart'); }, []);
  const centerX = 300;
  const centerY = 300;
  let layerThickness = 0;

  const calculatePolygonFrom = (radialDistances) => {
    // console.log('calculatePolygonFrom');
    const polygon = [];
    radialDistances.forEach((distance, index, array) => {
      const transformedPoint = DOMPoint.fromPoint({ x: 0, y: 0 }).matrixTransform(
        new DOMMatrix()
          .translate(centerX, centerY)
          .scale(layerThickness)
          .rotate((index / array.length) * 360)
          .translate(0, -distance)
          .scale(1, 1, 0), // otherwise it would be a 3d matrix
      );
      polygon.push(transformedPoint);
    });
    return polygon;
  };

  const svgPaintPolygon = (polygon, color = 'gray') => {
    // console.log('svgPaintPolygon');

    const polygonVertices = polygon.map((dompoint) => `${dompoint.x},${dompoint.y}`).join(' ');
    return `<polygon points="${polygonVertices}" class="polygon ${color}" />`;
  };

  const init = (answers) => {
    let polygons = '';

    const scaleMax = 5; // number of layers
    const diagramRadius = Math.min(centerX, centerY) * 0.6;
    layerThickness = diagramRadius / scaleMax;

    // paint the 12-gons dynamically until their size is decided
    for (let i = 0; i <= scaleMax; i += 1) {
      const flatArray = Array.from({ length: 12 }, () => i);
      polygons += svgPaintPolygon(calculatePolygonFrom(flatArray), `scale${i}`);
    }

    const polygon = calculatePolygonFrom(answers);
    polygons += svgPaintPolygon(polygon, 'answers');
    return polygons;
  };

  const answers = [];

  segments.forEach((segment) => {
    // console.log(segment);
    segment.questions.forEach((question) => {
      const value = parseInt(question.value, 10);
      if (typeof value === 'number') { answers.push(value); }
    });
  });

  // Answers on the results wheel are arranged like on a clock
  // the first answer [0] will be the top one and then clockwise
  const sortedAnswers = [
    answers[7],
    answers[8],
    answers[11],
    answers[9],
    answers[10],
    answers[5],
    answers[4],
    answers[3],
    answers[0],
    answers[2],
    answers[1],
    answers[6],
  ];
  const polygons = init(sortedAnswers);

  /* eslint-disable react/no-danger */
  return (
    <div className="maturity-svg-container">
      <svg className="maturity-svg" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
        <polygon points="-1,-1 300,300 -1,601" className="background organisation" />
        <polygon points="-1,-1 300,300 601,-1" className="background technology" />
        <polygon points="601,-1 300,300 601,601" className="background data" />
        <polygon points="601,601 300,300 -1,601" className="background process" />

        <g transform="translate(300, 300)">
          <line x1="0" y1="0" x2="0" y2="180" className="dashed" />
          <line x1="0" y1="0" x2="0" y2="180" transform="rotate(30)" className="dashed" />
          <line x1="0" y1="0" x2="0" y2="180" transform="rotate(60)" className="dashed" />
          <line x1="0" y1="0" x2="0" y2="180" transform="rotate(90)" className="dashed" />
          <line x1="0" y1="0" x2="0" y2="180" transform="rotate(120)" className="dashed" />
          <line x1="0" y1="0" x2="0" y2="180" transform="rotate(150)" className="dashed" />
          <line x1="0" y1="0" x2="0" y2="180" transform="rotate(180)" className="dashed" />
          <line x1="0" y1="0" x2="0" y2="180" transform="rotate(210)" className="dashed" />
          <line x1="0" y1="0" x2="0" y2="180" transform="rotate(240)" className="dashed" />
          <line x1="0" y1="0" x2="0" y2="180" transform="rotate(270)" className="dashed" />
          <line x1="0" y1="0" x2="0" y2="180" transform="rotate(300)" className="dashed" />
          <line x1="0" y1="0" x2="0" y2="180" transform="rotate(330)" className="dashed" />
          <line x1="0" y1="0" x2="0" y2="180" transform="rotate(300)" className="dashed" />
        </g>
        <g fontSize="20px">
          <text><tspan x="246" y="28">Technologie</tspan></text>
          <text><tspan x="261" y="585">Prozesse</tspan></text>
          <text transform="rotate(-90)"><tspan x="-365" y="28">Organisation</tspan></text>
          <text transform="rotate(90)"><tspan x="270" y="-573">Daten</tspan></text>
        </g>
        <g fontSize="12px" fontWeight="bold">
          <text><tspan x="160" y="132">Technologien</tspan></text>
          <text><tspan x="263" y="113">Werkzeuge</tspan></text>
          <text><tspan x="372" y="132">Schnittstellen</tspan></text>

          <text><tspan x="460" y="210">Verwendung</tspan></text>
          <text><tspan x="486" y="304">Erhebung</tspan></text>
          <text><tspan x="460" y="398">Bereitstellung</tspan></text>

          <text><tspan x="160" y="475">Beschreibung</tspan></text>
          <text><tspan x="282" y="496">Ablauf</tspan></text>
          <text>
            <tspan x="372" y="475">Sicherheit und</tspan>
            <tspan x="372" y="492">Berechtigungen</tspan>
          </text>

          <text>
            <tspan x="55" y="210">Digitalisierungs-</tspan>
            <tspan x="55" y="227">strategie</tspan>
          </text>
          <text><tspan x="48" y="304">Qualifikation</tspan></text>
          <text>
            <tspan x="60" y="398">Veränderungs-</tspan>
            <tspan x="60" y="415">bereitschaft</tspan>
          </text>

          <text><tspan x="296" y="304" className="scale0">0</tspan></text>
        </g>
        <g dangerouslySetInnerHTML={{ __html: polygons }}></g>
        <g fontSize="12px" fontWeight="bold">
          <text><tspan x="345" y="144" className="scale5">5</tspan></text>
          <text><tspan x="336" y="178" className="scale4">4</tspan></text>
          <text><tspan x="327" y="211" className="scale3">3</tspan></text>
          <text><tspan x="318" y="244" className="scale2">2</tspan></text>
          <text><tspan x="309" y="277" className="scale1">1</tspan></text>
          <text><tspan x="296" y="304" className="scale1">0</tspan></text>

        </g>
        <g fontSize="12px" fontWeight="bold">
          <text><tspan x="390" y="44" className="scale5">5 - stark ausgeprägt</tspan></text>
          <text><tspan x="390" y="61" className="scale1">0 - schwach ausgeprägt</tspan></text>
        </g>
      </svg>
    </div>
  );
};

export default ReifegradEvaluationChart;
