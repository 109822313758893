import React from 'react';

import ButtonLink from '../ButtonLink/ButtonLink';
import CardLocation from '../CardLocation';
import Image from '../Image';
import SectionWrapper from '../SectionWrapper';

import './WidgetLocations.scss';

/**
 * @return {html}
 */
const WidgetLocations = ({ component }) => {
  const componentImageAttributes = component.image?.data?.attributes;
  return (
    <SectionWrapper>
      <div className="sw-widget-locations">
        <div className="image-container">
          <Image
            alternativeText={componentImageAttributes?.alternativeText}
            aspectRatio="5:2"
            caption={componentImageAttributes?.caption}
            imageAttributes={componentImageAttributes}
          />
        </div>
        <div className="locations-container grid-1fr">
          <div className="grid-1x1fr-2x1fr-3x1fr">
            <div className="contact-card">
              <div className="mb-2">
                <div className="h6">Kontaktieren Sie uns</div>
                <div><a className="sw-link" href={`tel:${component.phoneBusiness}`}>{component.phoneBusiness}</a></div>
                <div><a className="sw-link" href={`mailto:${component.phoneBusiness}`}>{component.email}</a></div>
              </div>
              <div>
                <ButtonLink text="Kontakt" to="/kontakt" variant="pacific-blue" />
              </div>
            </div>
          </div>
          <div className="location-cards grid-1x1fr-2x1fr-3x1fr">
            {
              component.locations.data.map(location => (<CardLocation key={location.id} locationAttributes={location.attributes} />))
            }
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default WidgetLocations;
