import React, { useState, useEffect } from 'react';

import SectionContactForm from '../SectionContactForm';
import SectionContentCard from '../SectionContentCard';
import SectionCounter from '../SectionCounter';
import SectionCustomerCard from '../SectionCustomerCard';
import SectionEventList from '../SectionEventList';
import SectionEventProgram from '../SectionEventProgram';
import SectionFeatureImage from '../SectionFeatureImage';
import SectionFocusCard from '../SectionFocusCard';
import SectionIconCaption from '../SectionIconCaption';
import SectionIconCard from '../SectionIconCard';
import SectionImageBanner from '../SectionImageBanner';
import SectionImageGallery from '../SectionImageGallery';
import SectionImageSplit from '../SectionImageSplit';
import SectionImageText from '../SectionImageText';
import SectionJobList from '../SectionJobList';
import SectionMultiSelect from '../SectionMultiSelect';
import SectionNewsItems from '../SectionNewsItems';
import SectionPersonnelGallery from '../SectionPersonnelGallery';
import SectionPublication from '../SectionPublication';
import SectionQuoteCarousel from '../SectionQuoteCarousel';
import SectionSelfCheck from '../SectionSelfCheck';
import SectionSendInBlue from '../SectionSendInBlue';
import SectionText from '../SectionText';
import SectionVideoQuote from '../SectionVideoQuote';
import SectionYouTubeVideo from '../SectionYouTubeVideo';
import WidgetLocations from '../WidgetLocations';

import './PageContent.scss';
import SectionReifegrad from '../SectionReifegrad';

const InnerPageContent = ({ components }) => {
  if (!components || !components.length) {
    return <></>;
  }
  return (
    <>
      {
        components.map(component => {
          // eslint-disable-next-line no-underscore-dangle
          switch (component.__component) {
            case 'sections.contact-form-section':
              return (<SectionContactForm key={`contact-form-section-${component.id}`} component={component} />);
            case 'sections.content-card-section':
              return (<SectionContentCard key={`content-card-section-${component.id}`} component={component} />);
            case 'sections.counter-section':
              return (<SectionCounter key={`counter-section-${component.id}`} component={component} />);
            case 'sections.customer-card-section':
              return (<SectionCustomerCard key={`customer-card-section-${component.id}`} component={component} />);
            case 'sections.event-list-section':
              return (<SectionEventList key={`event-list-section-${component.id}`} component={component} />);
            case 'sections.event-program-section':
              return (<SectionEventProgram key={`event-program-section-${component.id}`} component={component} />);
            case 'sections.focus-card-section':
              return (<SectionFocusCard key={`focus-card-section-${component.id}`} component={component} />);
            case 'sections.feature-image-section':
              return (<SectionFeatureImage key={`feature-image-section-${component.id}`} component={component} />);
            case 'sections.icon-caption-section':
              return (<SectionIconCaption key={`icon-caption-section-${component.id}`} component={component} />);
            case 'sections.icon-card-section':
              return (<SectionIconCard key={`icon-card-section-${component.id}`} component={component} />);
            case 'sections.image-banner-section':
              return (<SectionImageBanner key={`image-banner-section-${component.id}`} component={component} />);
            case 'sections.image-gallery-section':
              return (<SectionImageGallery key={`image-gallery-section-${component.id}`} component={component} />);
            case 'sections.image-split-section':
              return (<SectionImageSplit key={`image-split-section-${component.id}`} component={component} />);
            case 'sections.image-text-section':
              return (<SectionImageText key={`image-text-section-${component.id}`} component={component} />);
            case 'sections.job-list-section':
              return (<SectionJobList key={`job-list-section-${component.id}`} component={component} />);
            case 'sections.news-items-section':
              return (<SectionNewsItems key={`news-items-section-${component.id}`} component={component} />);
            case 'sections.multi-select-section':
              return (<SectionMultiSelect key={`multi-select-section-${component.id}`} component={component} />);
            case 'sections.personnel-gallery-section':
              return (<SectionPersonnelGallery key={`personnel-gallery-section-${component.id}`} component={component} />);
            case 'sections.publication-section':
              return (<SectionPublication key={`publication-card-section-${component.id}`} component={component} />);
            case 'sections.quote-carousel-section':
              return (<SectionQuoteCarousel key={`quote-carousel-section-${component.id}`} component={component} />);
            case 'sections.self-check-section':
              return (<SectionSelfCheck key={`self-check-section-${component.id}`} component={component} />);
            case 'sections.reifegrad-section':
              return (<SectionReifegrad key={`reifegrad-section-${component.id}`} component={component} />);
            case 'sections.send-in-blue-section':
              return (<SectionSendInBlue key={`send-in-blue-section-${component.id}`} component={component} />);
            case 'sections.text-section':
              return (<SectionText key={`text-section-${component.id}`} component={component} />);
            case 'sections.video-quote-section':
              return (<SectionVideoQuote key={`video-quote-section-${component.id}`} component={component} />);
            case 'sections.you-tube-video-section':
              return (<SectionYouTubeVideo key={`you-tube-video-section-${component.id}`} component={component} />);
            case 'widgets.locations':
              return (<WidgetLocations key={`locations-widget-${component.id}`} component={component} />);
            default:
              return <></>;
          }
        })
      }
    </>
  );
};

/**
 * @return {html}
 */
const PageContent = ({ pageAttributes, useHeaderContent = false, isEmbeddedInHeader = false }) => {
  const selectedComponents = pageAttributes?.components?.filter(c => !!c.embedInHeader === useHeaderContent);
  const [showBackToTopButton, setShowBackToTopButton] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 1300) {
        setShowBackToTopButton(true);
      } else {
        setShowBackToTopButton(false);
      }
    };
    // Initial update
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const handleBackToTopClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  if (!selectedComponents || !selectedComponents.length) {
    return <></>;
  }
  if (useHeaderContent) {
    return (
      <div className={`sw-page-header-content ${isEmbeddedInHeader ? 'embedded' : 'not-embedded'}`}>
        <InnerPageContent components={selectedComponents} />
      </div>
    );
  }
  return (
    <main className="sw-page-content">
      <InnerPageContent components={selectedComponents} />
      {showBackToTopButton && (
        <button type="button" className="back-to-top-button" onClick={handleBackToTopClick}>
          <i className="icon icon-x3 icon-chevron-up"></i>
        </button>
      )}
    </main>
  );
};
export default PageContent;
