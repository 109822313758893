import React from "react";

import SectionWrapper from "../SectionWrapper";
import pagesList from "./pagesList";

import "./CreatePages.scss";

const pageListDelete = [
  "nachhaltigkeit",
  "organisationsentwicklung",
  "risikomanagement",
  "data-science",
  "cloud-computing",
  "branchen",
  "projektmanagement",
  "automotive",
  "public-sector",
  "veranstaltungen",
  "vortraege",
  "unser-vorstand",
  "unsere-werte",
  "innovation",
  "finanzwesen",
  "leistungen",
  "unsere-mitarbeitenden",
  "testautomatisierung",
  "smart-government",
  "meldewesen",
];

/**
 * @return {html}
 */
const CreatePages = () => {
  const createPage = (slug: string) => {
    const slugBig = slug
      .split("-")
      .map((page) => page[0].toUpperCase() + page.slice(1))
      .join(" ");

    const datum = {
      data: {
        title: slugBig,
        menuLabel: slugBig,
        slug,
        locale: "de",
        meta: {
          title: "Syncwork AG - Beraten | Gestalten | Digitalisieren",
          description: slugBig,
          keywords: slugBig,
        },
        header: {
          title: slugBig,
          subTitle: "Beraten | Gestalten | Digitalisieren",
          imageOverlay: "None",
          image: {
            data: {
              attributes: {},
            },
          },
        },
      },
    };

    fetch(`http://localhost/api/pages?filters[slug][%24eq]=${slug}`)
      .then((response) => response.json())
      .then((data) => {
        if (!data.data.length) {
          // Simple POST request with a JSON body using fetch
          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(datum),
          };
          fetch("http://localhost/api/pages", requestOptions)
            .then((response) => response.json())
            .then(() => {
              console.log("created: ", slug);
            });
        }
      });
  };

  function deletePage(slug: string): void {
    const requestOptions = {
      method: "DELETE",
    };
    fetch(`http://localhost/api/pages?filters[slug][%24eq]=${slug}`, requestOptions)
      .then((res) => res.text()) // or res.json()
      .then((res) => console.log(res));
  }

  function deletePages(): void {
    pageListDelete.map((page) => {
      deletePage(page);
      return true;
    });
  }

  function createPages(): void {
    pagesList.map((page) => {
      createPage(page);
      return true;
    });
  }

  return (
    <SectionWrapper classes="CreatePages">
      <br />
      <button type="button" onClick={createPages}>
        create the following pages:
      </button>
      <button type="button" onClick={deletePages} style={{ display: "none" }}>
        delete does not work at the moment
      </button>
      <div className="icon-list">
        {pagesList.map((pageName) => (
          <div key={pageName}>
            <div>{pageName}</div>
          </div>
        ))}
      </div>
    </SectionWrapper>
  );
};

export default CreatePages;
