import React from 'react';
import { useTranslation } from 'react-i18next';

import ButtonLink from '../ButtonLink';

import './CardEventCompact.scss';

/**
 * @return {html}
 */
const CardEventCompact = ({ eventAttributes, theme = 'default' }) => {
  const { t } = useTranslation();
  const dateStart = new Date(eventAttributes.dateStart).toLocaleDateString('de-DE');
  // const dateEnd = item.dateEnd ? new Date(item.dateEnd).toLocaleDateString('de-DE') : null;

  return (
    <div className={`sw-card-event-compact sw-card-event-compact-${theme}`}>
      <div className="details">
        <div className="date">
          <span>{dateStart}</span>
        </div>
        <div className="title">{eventAttributes.title}</div>
        <div className="cta">
          <ButtonLink text={t('common.register')} to={eventAttributes.url} variant={theme} />
        </div>
      </div>
    </div>
  );
};

export default CardEventCompact;
