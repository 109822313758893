import React from 'react';
import { useLocation } from 'react-router-dom';

import NewsItemPageDetails from '../NewsItemPageDetails';

import './NewsItemPageContent.scss';

interface IState {
  from: string;
}

/**
 * @return {html}
 */
const NewsItemPageContent = ({ newsItemAttributes }) => {
  const location = useLocation<IState>();
  const from = location.state?.from;

  return (
    <main className="sw-page-content">
      <NewsItemPageDetails newsItemAttributes={newsItemAttributes} from={from} />
    </main>
  );
};

export default NewsItemPageContent;
