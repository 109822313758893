import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/*
import ApiService from '../../services/ApiService';

const subDomainKey = getSubDomainKey();
const getTimestamp = () => new Date().getTime().toString();
*/
/**
 * @return {html}
 */
const PageViewTracker = () => {
  const history = useHistory();

  function trackPageView() {
    /* todo: getv4towork auskommentiert */
    console.log("was war das denn hier eigentlich", 'ApiService.post(/page-view-tracker/{subDomainKey}?{getTimestamp()})');
  }

  useEffect(() => {
    trackPageView();
    history.listen(trackPageView); // Track all subsequent pageviews
  }, [history]);

  return (<></>);
};

export default PageViewTracker;
