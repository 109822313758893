const pagesList = [
  "ai",
  "automotive",
  "branchen",
  "business-applications",
  "cloud-computing",
  "data-science",
  "datenschutz",
  "digital-validation",
  "finanzwesen",
  "impressum",
  "industrie",
  "innovation",
  "it-beschaffungsmanagement",
  "kontakt",
  "leistungen",
  "life-science",
  "meldewesen",
  "nachhaltigkeit",
  "news",
  "organisationsentwicklung",
  "ozg",
  "projektmanagement",
  "prozessdigitalisierung",
  "public-sector",
  "risikomanagement",
  "rpa",
  "siem",
  "smart-government",
  "testautomatisierung",
  "unser-vorstand",
  "unsere-mitarbeitenden",
  "unsere-werte",
  "veranstaltungen",
  "vortraege",
];

export default pagesList;
