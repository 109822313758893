import axios from 'axios';
import { StrapiBackEndUrlKind, getStrapiBackEndUrl } from '../utils';

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Authorization: '', // This is to prevent the browser from sending Basic (which we use in the beta environment)
  },
  baseURL: getStrapiBackEndUrl(StrapiBackEndUrlKind.Api),
});

class ApiService {
  get(uri) {
    console.log(`uri: ${uri}`);
    return axiosInstance.get(uri);
  }

  /*
  async getAndLog(uri) {
    const r = await axiosInstance.get(uri);

    console.log(r);

    return r;
  }
  */

  graphQL(graphQL) {
    return axiosInstance.post('/graphql', graphQL)
      .then((response) => {
        if (response.data.errors) {
          throw response.data.errors[0];
        }
        return response;
      });
  }

  delete(uri) {
    return axiosInstance.delete(uri);
  }

  post(uri) {
    return axiosInstance.post(uri);
  }
}

export default new ApiService();
