import React from 'react';

import CardFocus from '../CardFocus/CardFocus';
import SectionWrapper from '../SectionWrapper';
import { camelToKebab } from '../../utils';

import './SectionFocusCard.scss';

interface IProps {
  component: any;
}

/**
 * @return {html}
 */
const SectionFocusCard = ({ component }: IProps) => {
  const theme = camelToKebab(component.theme);

  return (
    <SectionWrapper
      classes={`sw-section-focus-card sw-section-focus-card-${theme}`}
      collapseBottom={component.collapseBottom}
      collapseTop={component.collapseTop}
      id={component.anchorId || `section-focus-card-${component.id}`}
    >
      <div className="grid-1fr-1fr2fr grid-gap">
        <div>
          {
            component.title && (
              <>
                <h2 className="h5">{component.title}</h2>
                {
                  component.subTitle && (<div className="h6">{component.subTitle}</div>)
                }
              </>
            )
          }
        </div>
        <div className="grid-1x1fr-2x1fr-3x1fr">
          {
            component.cards.map(card => (<CardFocus key={card.id} card={card} theme={theme} />))
          }
        </div>
      </div>
    </SectionWrapper>
  );
};

export default SectionFocusCard;
