import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import qs from 'qs';
import ApiService from '../../../services/ApiService';
import Statuses from '../../types/Statuses';

interface IJobs {
  jobs: any[];
  status: string;
  error: string;
}

const initialState: IJobs = {
  jobs: [],
  status: Statuses.idle,
  error: null,
};

export const getJobQueryPopulate = () => [
  'meta',
  'contactPerson',
  'contactPerson.address',
  'contactPerson.image',
  'contractTypes',
  'locations',
  'targetPersons',
];

export const fetchJobs = createAsyncThunk('jobs/fetchJobs', async () => {
  // const todayDate = moment().format('YYYY-MM-DD');

  const query = qs.stringify({
    /*
    todo: getv4towork auskommentiert
    filters: {
      visibleFrom: {
        $lte: todayDate,
      },
    },
    */
    populate: getJobQueryPopulate(),
  },
  {
    encodeValuesOnly: true,
  });

  const url = `/jobs?${query}`;
  const response = await ApiService.get(url);

  return response.data;
});

export const JobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(fetchJobs.pending, (state) => {
        state.status = Statuses.loading;
      })
      .addCase(fetchJobs.fulfilled, (state, action) => {
        state.status = Statuses.succeeded;
        // Add fetched Jobs to the store
        state.jobs = action.payload.data;
      })
      .addCase(fetchJobs.rejected, (state, action) => {
        state.status = Statuses.failed;
        state.error = action.error.message;
      });
  },
});

export const selectJobs = state => state.jobs.jobs;

export const selectJobBySlug = slug => state => selectJobs(state).find(job => job.slug === slug);

export default JobsSlice.reducer;
