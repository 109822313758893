import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import DynamicLink from '../DynamicLink';
import SectionWrapper from '../SectionWrapper';
import SocialMediaShare from '../SocialMediaShare/SocialMediaShare';

import './NewsItemPageDetails.scss';

/**
 * @return {html}
 */
const NewsItemPageDetails = ({ newsItemAttributes, from = null }) => {
  const newsItemDate = new Date(newsItemAttributes.date).toLocaleDateString('de-DE');
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <SectionWrapper>
      <div className="sw-section-news-item-page-details">
        <div className="grid-1fr-1fr2fr grid-gap">
          <div />
          <div>{newsItemDate}</div>
        </div>

        <div className="grid-1fr-1fr2fr grid-gap">
          <div />
          <div className="teaser">
            {newsItemAttributes.teaser}
          </div>
        </div>
        {
          newsItemAttributes.content && (
            <div className="grid-1fr-1fr2fr grid-gap">
              <div />
              <div>
                <div className="strapi-markdown">
                  <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
                    {newsItemAttributes.content}
                  </ReactMarkdown>
                </div>
              </div>
            </div>
          )
        }

        {
          // return 0 which is rendered, must be boolean
          !!(newsItemAttributes.additionalLinks.length) && (
            <div className="grid-1fr-1fr2fr grid-gap">
              <div />
              <div className="grid-1fr">
                <div className="related-links">{t('common.related-links')}</div>
                <div>
                  <ul>
                    {
                      newsItemAttributes.additionalLinks.map(link => (<li key={link.id}><DynamicLink to={link.url} className="sw-link">{link.text}</DynamicLink></li>))
                    }
                  </ul>
                </div>
              </div>
            </div>
          )
        }

        <div className="grid-1fr-1fr2fr grid-gap">
          <div />
          <div className="grid-1fr">
            <div className="font-weight-600">{t('common.share-on')}</div>
            <SocialMediaShare />
          </div>
        </div>

        {
          from && (
            <div className="grid-1fr-1fr2fr grid-gap">
              <div />
              <div>
                <i className="icon icon-x1 icon-arrow-left" />
                <button type="button" className="sw-link" onClick={() => history.goBack()}>
                  {t('common.back-to-all-news-items')}
                </button>
              </div>
            </div>
          )
        }
      </div>
    </SectionWrapper>
  );
};

export default NewsItemPageDetails;
