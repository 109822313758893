import React from 'react';

import FormSendInBlue from '../FormSendInBlue';
import SectionWrapper from '../SectionWrapper';
import { camelToKebab } from '../../utils';

import './SectionSendInBlue.scss';

/**
 * @return {html}
 */
const SectionSendInBlue = ({ component }) => {
  const theme = camelToKebab(component.theme);

  return (
    <SectionWrapper
      classes={`sw-section-send-in-blue sw-section-send-in-blue-${theme}`}
      collapseBottom={component.collapseBottom}
      collapseTop={component.collapseTop}
      id={component.anchorId || `section-send-in-blue-${component.id}`}
    >
      <div className={`grid-1fr-1fr2fr grid-gap ${!component.title ? 'untitled' : ''}`.trim()}>
        <div>
          {
            component.title && (
              <>
                <h2 className="h5">{component.title}</h2>
                {
                  component.subTitle && (<div className="h6">{component.subTitle}</div>)
                }
              </>
            )
          }
        </div>
        <div>
          <FormSendInBlue form={component.form} />
        </div>
      </div>
    </SectionWrapper>
  );
};

export default SectionSendInBlue;
