import React from 'react';

import CardImageText from '../CardImageText';
import SectionWrapper from '../SectionWrapper';
import { camelToKebab } from '../../utils';

import './SectionImageText.scss';

/**
 * @return {html}
 */
const SectionImageText = ({ component }) => {
  const theme = camelToKebab(component.theme);

  return (
    <SectionWrapper
      classes={`sw-section-image-text sw-section-image-text-${theme}`}
      collapseBottom={component.collapseBottom}
      collapseTop={component.collapseTop}
      id={component.anchorId || `section-image-text-${component.id}`}
    >
      <div className="grid-1fr-1fr2fr grid-gap">
        <div>
          {
            component.title && (
              <>
                <h2 className="h5">{component.title}</h2>
                {
                  component.subTitle && (<div className="h6">{component.subTitle}</div>)
                }
              </>
            )
          }
        </div>

        <div>
          {
            component.cards.map(imageTextCard => (<CardImageText key={imageTextCard.id} card={imageTextCard} />))
          }
        </div>
      </div>
    </SectionWrapper>
  );
};

export default SectionImageText;
