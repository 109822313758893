import React from 'react';

import CardVideoQuote from '../CardVideoQuote';
import SectionWrapper from '../SectionWrapper';
import { camelToKebab } from '../../utils';

import './SectionVideoQuote.scss';

/**
 * @return {html}
 */
const SectionVideoQuote = ({ component }) => {
  const theme = camelToKebab(component.theme);

  return (
    <SectionWrapper
      classes={`sw-section-video-quote sw-section-video-quote-${theme}`}
      collapseBottom={component.collapseBottom}
      collapseTop={component.collapseTop}
      id={component.anchorId || `section-video-quote-${component.id}`}
    >
      <div className="grid-1fr-1fr2fr grid-gap">
        <div>
          {
            component.title && (
              <>
                <h2 className="h5">{component.title}</h2>
                {
                  component.subTitle && (<div className="h6">{component.subTitle}</div>)
                }
              </>
            )
          }
        </div>

        <div>
          {
            component.cards.map(card => (<CardVideoQuote key={card.id} card={card} />))
          }
        </div>
      </div>
    </SectionWrapper>
  );
};

export default SectionVideoQuote;
