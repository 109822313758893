import React from 'react';

import SectionWrapper from '../SectionWrapper';
import YouTubeVideo from '../YouTubeVideo';

import './SectionYouTubeVideo.scss';

interface IProps {
  component: any;
}

/**
 * @return {html}
 */
const SectionYouTubeVideo = ({ component }: IProps) => {
  const ytImageAttributes = component.image?.data?.attributes.formats.large || { name: component.videoId, url: `https://img.youtube.com/vi/${component.videoId}/maxresdefault.jpg` };

  return (
    <SectionWrapper
      classes="sw-section-youtube-video"
      collapseBottom={component.collapseBottom}
      collapseTop={component.collapseTop}
      id={component.anchorId || `section-youtube-video-${component.id}`}
    >
      <div className="grid-1fr-1fr2fr grid-gap">
        <div>
          {
            component.title && (
              <>
                <h2 className="h5">{component.title}</h2>
                {
                  component.subTitle && (<div className="h6">{component.subTitle}</div>)
                }
              </>
            )
          }
        </div>

        <div className="grid-1x1fr">
          <YouTubeVideo title={component.title} videoId={component.videoId} imageAttributes={ytImageAttributes} />
        </div>
      </div>
    </SectionWrapper>
  );
};

export default SectionYouTubeVideo;
